import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import { PrintHeaderWithLogo, PsLabel, formToObject } from "../../../utils";
import { _POST_CONVACATION_REGISTRATION } from "../../../models/iformModel";
import { liststDepartments } from "../../../models/hr";
import { ServiceUrl } from "../../../utils/serviceUrl";
import axios from "axios";

const ConvacationRegistration = (props) => {
  const [loader, setLoader] = useState(false);
  const [validate, setValidate] = useState(false);
  const [hstudies, setHstudies] = useState("0");
  const [employed, setEmployed] = useState("0");
  const [selfEmployed, setSelfEmployed] = useState("0");
  const [entrepreneur, setEetrepreneur] = useState("0");
  const [stdepartments, setstDepartments] = useState([]);

  useEffect(() => {
    setLoader(true);
    axios.post(ServiceUrl.OPEN_ACCESS.DEPARTMENTS).then((res) => {
      console.log(res);
      if (res["data"].status == "1") {
        setstDepartments(res["data"].data);
      }
      setLoader(false);
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidate(true);
      return;
    }
    if (!window.confirm("Do you want to submit ?")) return;
    setLoader(true);
    _POST_CONVACATION_REGISTRATION(formToObject(form)).then((res) => {
      if (res.status == "1") {
        alert(res.message || "Sent");
        form.reset();
        window.location.reload();
      } else {
        alert(res.message || "Unknown Error");
      }
      setLoader(false);
    });
  };

  return (
    <>
      <div className="container-sm">
        <Card style={{ border: "2px solid" }} className="py-2 px-3 mt-2">
          <Spin spinning={loader}>
            <Form noValidate validated={validate} onSubmit={handleFormSubmit}>
              <Row>
                <Col md={11}>
                  <PrintHeaderWithLogo />
                </Col>
                <Col md={1}></Col>
                <Col md={12}>
                  <p className="text-center mt-2 font-bold fs-10">
                    CONVOCATION REGISTRATION
                  </p>
                  <p className="text-center font-bold fs-10 m-0">
                    FEBRUARY 2025
                  </p>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={6}>
                  <PsLabel text="Name" className="fs-sm" required />
                  <Form.Control
                    type="text"
                    name="student_name"
                    className="fw-bold"
                    required
                  />
                </Col>
                <Col md={6}>
                  <PsLabel text="Reg.No" className="fs-sm" required />
                  <Form.Control
                    type="text"
                    name="register_no"
                    className="fw-bold"
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <PsLabel className="me-3 fs-sm" text="Department" required />
                  <Form.Control
                    as="select"
                    name="department"
                    className="fw-bold form-select form-select-sm"
                    size="sm"
                    required
                  >
                    <option value="">-Select-</option>
                    {stdepartments.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.department} (
                        {item.dept_type == "unaided" ? "Un-aided" : "Aided"})
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col md={2}>
                  <PsLabel
                    className="me-3 fs-sm"
                    text="Year of Passing"
                    required
                  />
                  <Form.Control
                    type="text"
                    name="year_passing"
                    className="fw-bold"
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={4}>
                  <PsLabel className="me-3 mb-2 fs-sm" text="Gender" required />{" "}
                  <br />
                  <input
                    type="radio"
                    name="gender"
                    id=""
                    className=""
                    value="male"
                  />
                  <span className="ms-1 mb-1">Male</span>
                  <input
                    type="radio"
                    name="gender"
                    id=""
                    className="ms-4"
                    value="female"
                  />
                  <span className="ms-1 mb-1">Female</span>
                  <input
                    type="radio"
                    name="gender"
                    id=""
                    className="ms-4"
                    value="others"
                  />
                  <span className="ms-1 mb-1">Others</span>
                </Col>
                <Col md={2}>
                  <PsLabel className="me-3 mb-2 fs-sm" text="Course" required />{" "}
                  <br />
                  <input
                    type="radio"
                    name="course"
                    id=""
                    className=""
                    value="ug"
                  />
                  <span className="ms-1 mb-1">UG</span>
                  <input
                    type="radio"
                    name="course"
                    id=""
                    className="ms-4"
                    value="pg"
                  />
                  <span className="ms-1 mb-1">PG</span>
                </Col>
                <Col md={4} className="text-center">
                  <PsLabel className="me-3 mb-2 fs-sm" text="Shift" required />{" "}
                  <br />
                  <input
                    type="radio"
                    name="shift"
                    id=""
                    className=""
                    value="I"
                  />
                  <span className="ms-1 mb-1">Shift-I</span>
                  <input
                    type="radio"
                    name="shift"
                    id=""
                    className="ms-4"
                    value="II"
                  />
                  <span className="ms-1 mb-1">Shift-II</span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <PsLabel text="APAAR ID:" className="fs-sm" required />
                  <span className="ms-2">
                    (Automated Permanent Academic Account Registry) To Register
                    :{" "}
                    <a href="https://apaar.education.gov.in/" target="_blank">
                      https://apaar.education.gov.in/
                    </a>
                  </span>
                  <Form.Control
                    type="text"
                    pattern="[A-Za-z0-9]{12}"
                    name="apaar_id"
                    className="fw-bold"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={4}>
                  <PsLabel
                    text="Residential Address"
                    className="fs-sm"
                    required
                  />
                  <Form.Control
                    type="text"
                    name="address"
                    className="fw-bold"
                    required
                  />
                </Col>
                <Col md={4}>
                  <PsLabel text="Email Id" className="fs-sm" required />
                  <Form.Control
                    type="email"
                    name="email"
                    className="fw-bold"
                    required
                  />
                </Col>
                <Col md={4}>
                  <PsLabel
                    text="Mobile No (WhatsApp)"
                    className="fs-sm"
                    required
                  />
                  <Form.Control
                    type="number"
                    name="mobile"
                    pattern="[0-9]{10}"
                    className="fw-bold"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={3}>
                  <PsLabel
                    className="me-3 mb-2 fs-sm"
                    text="Are you pursuing Higher Studies"
                    required
                  />
                </Col>
                <Col md={9}>
                  <input
                    type="radio"
                    name="hstudies"
                    onChange={() => setHstudies("1")}
                  />
                  <span className="ms-1 mb-1">Yes</span>
                  <input
                    type="radio"
                    name="hstudies"
                    className="ms-4"
                    onChange={() => setHstudies("0")}
                  />
                  <span className="ms-1 mb-1">No</span>
                  <input
                    type="hidden"
                    name="is_higher_studies"
                    value={hstudies}
                  />
                </Col>
              </Row>
              {hstudies == "1" && (
                <Row className="mt-3">
                  <Col md={4}>
                    <PsLabel text="Course Name" className="fs-sm" required />
                    <Form.Control
                      type="text"
                      name="course_name"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <PsLabel text="Major" className="fs-sm" required />
                    <Form.Control
                      type="text"
                      name="major"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <PsLabel
                      text="College/University/Institution"
                      className="fs-sm"
                      required
                    />
                    <Form.Control
                      type="text"
                      name="college_name"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <PsLabel
                      text="Address of College/University/Institution:"
                      className="fs-sm"
                      required
                    />
                    <Form.Control
                      type="text"
                      name="college_address"
                      className="fw-bold"
                      required
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col md={3}>
                  <PsLabel
                    className="me-3 mb-2 fs-sm"
                    text="Are you Employed?"
                    required
                  />
                </Col>
                <Col md={9}>
                  <input
                    type="radio"
                    name="employed"
                    onChange={() => setEmployed("1")}
                  />
                  <span className="ms-1 mb-1">Yes</span>
                  <input
                    type="radio"
                    name="employed"
                    className="ms-4"
                    onChange={() => setEmployed("0")}
                  />
                  <span className="ms-1 mb-1">No</span>
                  <input type="hidden" name="is_employee" value={employed} />
                </Col>
              </Row>
              {employed == "1" && (
                <Row className="mt-3">
                  <Col md={4}>
                    <PsLabel text="Designation" required />
                    <Form.Control
                      type="text"
                      name="emp_designation"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <PsLabel text="Company/Institution Address" required />
                    <Form.Control
                      type="tect"
                      name="emp_company_address"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <PsLabel text="Annual Salary" required />
                    <Form.Control
                      type="text"
                      name="emp_annual_salary"
                      className="fw-bold"
                      required
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col md={3}>
                  <PsLabel
                    className="me-3 mb-2 fs-sm"
                    text="Are you self-Employed?"
                    required
                  />
                </Col>
                <Col md={9}>
                  <input
                    type="radio"
                    name="self_employed"
                    onChange={() => setSelfEmployed("1")}
                  />
                  <span className="ms-1 mb-1">Yes</span>
                  <input
                    type="radio"
                    name="self_employed"
                    className="ms-4"
                    onChange={() => setSelfEmployed("0")}
                  />
                  <span className="ms-1 mb-1">No</span>
                  <input
                    type="hidden"
                    name="is_self_employee"
                    value={selfEmployed}
                  />
                </Col>
              </Row>
              {selfEmployed == "1" && (
                <Row className="mt-3">
                  <Col md={6}>
                    <PsLabel text="Work" required />
                    <Form.Control
                      type="text"
                      name="self_emp_work"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <PsLabel text="Annual Salary" required />
                    <Form.Control
                      type="tect"
                      name="self_emp_salary"
                      className="fw-bold"
                      required
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col md={3}>
                  <PsLabel
                    className="me-3 mb-2 fs-sm"
                    text="Are you an Entrepreneur?"
                    required
                  />
                </Col>
                <Col md={9}>
                  <input
                    type="radio"
                    name="entrepreneur"
                    onChange={() => setEetrepreneur("1")}
                  />
                  <span className="ms-1 mb-1">Yes</span>
                  <input
                    type="radio"
                    name="entrepreneur"
                    className="ms-4"
                    onChange={() => setEetrepreneur("0")}
                  />
                  <span className="ms-1 mb-1">No</span>
                  <input
                    type="hidden"
                    name="is_entrepreneur"
                    value={entrepreneur}
                  />
                </Col>
              </Row>
              {entrepreneur == "1" && (
                <Row className="mt-3">
                  <Col md={6}>
                    <PsLabel text="Work" required />
                    <Form.Control
                      type="text"
                      name="entrepreneur_work"
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <PsLabel text="Annual Salary" required />
                    <Form.Control
                      type="tect"
                      name="entrepreneur_salary"
                      className="fw-bold"
                      required
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col md={12} className="text-end">
                  <Button type="submit">SUBMIT</Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>
    </>
  );
};

export default ConvacationRegistration;
